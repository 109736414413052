import React from "react";
import "./Menu.css";
import Logotype from "../../images/logo.svg";
import LogoMobile from "../../images/logo-mobile.svg";
import Home from "../../images/home.svg";
import cart from "../../images/cart-icon.svg";
import user from "../../images/user-icon.svg";
import { Link } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";

function Menu(props) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const redirectToCart = () => {
    window.open("https://traceaudio.com/cart", "_blank", "noreferrer");
  };

  function changeStep(step) {
    props.setActiveStep(step);
    window.sessionStorage.setItem("step", JSON.stringify(step));
  }

  return (
    <Box className="menu">
      <nav>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="/" onClick={(e) => props.setActiveStep(0)}>
            <img src={isSmallScreen ? LogoMobile : Logotype} className={isSmallScreen ? "logoMobileIcon" : "logoIcon"} alt="Logotype" />
          </Link>
          <Link to="/" onClick={(e) => props.setActiveStep(0)}>
            <img src={Home} style={{ marginLeft: "10px" }} alt="Logotype" />
          </Link>
        </Box>
        {props.activeStep > 0 && (
          <Box>
            <p style={{ fontSize: "18px", fontWeight: 700, color: "#6b6b6b" }}>{props.template?.name}</p>
          </Box>
        )}
        <Box className="menuIcons">
          <Link to="/admin" onClick={(e) => props.setActiveStep(0)}>
            <img
              style={{ borderRadius: "12px", backgroundColor: "#474747", padding: "10px" }}
              width={20}
              height={20}
              src={user}
              alt="User"
            />
          </Link>
          <Box className="manuCart" onClick={redirectToCart}>
            <img
              style={{ borderRadius: "12px", backgroundColor: "#474747", padding: "10px" }}
              width={20}
              height={20}
              src={cart}
              alt="Cart"
            />
          </Box>
        </Box>
      </nav>
      {props.activeStep > 0 ? (
        <Box className="stepsList">
          <p className={props.activeStep === 1 ? "stepsListActive" : ""} onClick={(e) => changeStep(1)}>
            01. COLOR
          </p>
          <p className={props.activeStep === 2 ? "stepsListActive" : ""} onClick={(e) => changeStep(2)}>
            02. LABELS
          </p>
          <p className={props.activeStep === 3 ? "stepsListActive" : ""} onClick={(e) => changeStep(3)}>
            03. LOGO
          </p>
          <p className={props.activeStep === 4 ? "stepsListActive" : ""} onClick={(e) => changeStep(4)}>
            04. PREVIEW
          </p>
        </Box>
      ) : null}
    </Box>
  );
}

export default Menu;
