import React from "react";
import "./DesignBlock.css";
import design_Block from "../../../images/designBlock.png";
import link from "../../../images/link.svg";
import edit from "../../../images/edit.svg";
import cart_image from "../../../images/cart_image.svg";
import { useNavigate } from "react-router-dom";
import templates from "../../../static/templates.json";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

function DesignBlock(props) {
  const navigate = useNavigate();

  function orderItem() {
    const newData = [
      {
        settings: props.data.DesignData[0].settings,
        logotype:
          props.data.DesignLogotype === null
            ? ""
            : props.data.DesignLogotype.includes(process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com")
              ? `${props.data.DesignLogotype}`
              : `${process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com"}${props.data.DesignLogotype}`,
        amount: Number(props.data.Amount.replaceAll('"', "")),
        data: props.data.DesignData[0].data,
        groups: props.data.DesignData[0].groups,
        isEditing: true,
        Id: props.data.Id,
      },
    ];

    window.sessionStorage.setItem("data", JSON.stringify(newData));
    const templateData = templates.find((e) => e.id === props.data.DesignId);
    window.sessionStorage.setItem("template", JSON.stringify(templateData));
    window.sessionStorage.setItem("step", JSON.stringify(4));
    window.sessionStorage.setItem("savedActiveStep", 5);
    props.setActiveStep(4);
    navigate("/");
  }

  const edititem = () => {
    const newData = [
      {
        settings: props.data.DesignData[0].settings,
        logotype:
          props.data.DesignLogotype === null
            ? ""
            : props.data.DesignLogotype.includes(process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com")
              ? `${props.data.DesignLogotype}`
              : `${process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com"}${props.data.DesignLogotype}`,
        amount: Number(props.data.Amount.replaceAll('"', "")),
        data: props.data.DesignData[0].data,
        groups: props.data.DesignData[0].groups,
        isEditing: true,
        Id: props.data.Id,
      },
    ];

    window.sessionStorage.setItem("data", JSON.stringify(newData));
    const templateData = templates.find((e) => e.id === props.data.DesignId);
    window.sessionStorage.setItem("template", JSON.stringify(templateData));
    window.sessionStorage.setItem("step", JSON.stringify(1));
    props.setActiveStep(1);
    navigate("/");
  };

  return (
    <div className="designBlock" style={{ overflowX: "hidden" }}>
      <p className="designBlockTitle">{props.data.DesignId}</p>
      <img
        onClick={edititem}
        style={{ width: "105%", marginLeft: "-5.5%" }}
        src={
          props.data.ModelImage !== null
            ? process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com" + `${props.data.ModelImage}`
            : design_Block
        }
        alt="design block"
      />
      <div className="designBlockBottom">
        <button
          data-tooltip-id={`my-tooltip-${props.data.DesignId}`}
          data-tooltip-content="Copied!"
          onClick={(e) => {
            navigator.clipboard.writeText(window.location.origin + "/preview/" + props.data.Id);
          }}
        >
          <img src={link} alt="Link" />
        </button>
        {!props.disableDelete && (
          <button onClick={() => props.handleOpenModal(props.data.Id)}>
            <p>Delete</p>
          </button>
        )}
        <button onClick={edititem}>
          <img src={edit} alt="Edit" />
          <p>Edit</p>
        </button>
        <button onClick={orderItem}>
          <img src={cart_image} alt="Cart" />
          <p>Order</p>
        </button>
      </div>
      <Tooltip id={`my-tooltip-${props.data.DesignId}`} openOnClick={true} closeOnScroll closeOnEsc />
    </div>
  );
}

export default DesignBlock;
