import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import right_arrow from "../images/right_arrow.svg";
import qs from "qs";
import { Box, Typography, Button, Alert, Snackbar } from "@mui/material";
import loading from "../images/loading.svg";
import templates from "../static/templates.json";

const Login = ({ setActiveStep, setData }) => {
  const [authLoading, setAuthLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const savedDesign = JSON.parse(sessionStorage.getItem("savedDesign"));

  const getDesigns = async (orderID) => {
    const token = localStorage.getItem("token");

    await axios
      .get(process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com" + "/api/v1/designs", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data.find((e) => e.Id === Number(orderID));
        const newData = [
          {
            settings: data.DesignData[0].settings,
            logotype: data.DesignLogotype || null,
            amount: Number(data.Amount.replaceAll('"', "")),
            data: data.DesignData[0].data,
            groups: data.DesignData[0].groups,
            isEditing: true,
            isSharedDesign: false,
            isEditFromCart: true,
            Id: data.Id,
          },
        ];

        /* REDIRECT TO EDIT PAGE */

        window.sessionStorage.setItem("data", JSON.stringify(newData));
        const templateData = templates.find((e) => e.id === data.DesignId);
        const savedActiveStep = JSON.parse(sessionStorage.getItem("savedActiveStep"));
        window.sessionStorage.setItem("template", JSON.stringify(templateData));
        if (savedActiveStep === 0) {
          return navigate("/admin");
        }
        window.sessionStorage.setItem("step", JSON.stringify(savedActiveStep));
        setActiveStep(savedActiveStep);
        setData(newData);
        setAuthLoading(false);
        navigate("/");
      })
      .catch((err) => {
        setAuthLoading(false);
        console.log(err);
      });
  };

  const saveDesign = async () => {
    const templateID = sessionStorage.getItem("savedTempate");

    const template = templates.find((item) => item.id === templateID);

    const orderData = {
      DesignName: template.name,
      DesignId: template.id,
      DesignData: [
        {
          data: savedDesign.data.map((item) => {
            return item;
          }),
          groups: savedDesign.groups,
          settings: savedDesign.settings,
        },
      ],
      DesignDescription: template.DesignDescription,
      DesignLogotype: savedDesign.logotype || null,
      Amount: savedDesign.amount,
      Status: 1,
      Id: savedDesign.Id !== undefined ? savedDesign.Id : null,
    };

    const formData = new FormData();

    if (savedDesign?.logotype && !savedDesign?.logotype?.includes("http")) {
      formData.append("file", savedDesign.logotype);
    }

    formData.append("data", JSON.stringify(orderData));

    const token = localStorage.getItem("token");

    const designConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com" + "/api/v1/addDesign",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .request(designConfig)
      .then((response) => {
        getDesigns(response.data.designId);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setErrorMessage(error.response.data.message);
        setAuthLoading(false);
      });
  };

  const authUser = async () => {
    setAuthLoading(true);
    const data = qs.stringify({
      Email: email,
      Password: password,
    });

    const authConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com" + "/api/v1/login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data,
    };

    await axios
      .request(authConfig)
      .then((response) => {
        if (response.data.adminStatus >= 0) {
          sessionStorage.setItem("adminStatus", response.data.adminStatus);
        }

        if (response.data.token) {
          localStorage.setItem("token", response.data.token);

          if (savedDesign) {
            saveDesign();
          } else {
            navigate("/admin");
            setAuthLoading(false);
          }
        } else {
          setAuthLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        setErrorMessage(error.response.data);
        setAuthLoading(false);
      });
  };

  const createDesignAsGuest = () => {
    setActiveStep(0);
    navigate("/");
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  useEffect(() => {
    const savedDesign = sessionStorage.getItem("savedDesign");
    if (savedDesign !== null) {
      handleOpenSnackbar();
    }
  }, []);

  const isDisabled = email.length < 6 || password.length < 6 || authLoading;

  return (
    <>
      <Box className="loginPage">
        <h1>Login</h1>
        <p className="loginSubtitle">Login or register to save and share designs. This account is different than your Shopify account.</p>
        <Box className="loginInput">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="username@email.com"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
        </Box>
        <Box className="loginInput">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="password"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        {errorMessage?.length ? <Typography sx={{ color: "red", fontFamily: "Abel" }}>{errorMessage}</Typography> : null}
        <Button
          onClick={() => navigate("/forgot-password")}
          sx={{ textTransform: "none", color: "#000000", fontFamily: "Abel", marginBottom: "6px" }}
        >
          Forgot Password?
        </Button>
        <Box sx={{ display: "flex", width: "100%", maxWidth: "400px" }}>
          <Button
            variant="contained"
            disabled={isDisabled}
            sx={{
              width: "100%",
              height: "50px",
              flexDirection: "row",
              alignItems: "center",
              borderRadius: "12px",
              backgroundColor: "#7A92BA",
            }}
            onClick={(e) => authUser()}
          >
            <Typography sx={{ fontFamily: "Abel", fontSize: "20px", marginRight: "6px" }}>Sign in</Typography>
            <img src={right_arrow} alt="Right Arrow" />
            {authLoading && (
              <img style={{ width: "20px", marginLeft: "10px", position: "relative", left: "10px" }} src={loading} alt="Loading" />
            )}
          </Button>
        </Box>
        <p className="loginBottomText">
          Don’t have an account yet? <Link to={"/signup"}>Sign up</Link>
        </p>
        <Box sx={{ cursor: "pointer", marginTop: "30px" }} onClick={createDesignAsGuest}>
          <Typography sx={{ fontFamily: "Abel", color: "#000000", textAlign: "center", fontSize: "13px", textDecorationLine: "underline" }}>
            Create Design as Guest
          </Typography>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        sx={{
          marginTop: "5%",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" variant="filled" sx={{ width: "100%" }}>
          Please login first to save your design
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
