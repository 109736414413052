import React from "react";
import plus from "../../images/plus.svg";
import my_designs from "../../images/my_designs.svg";
import "./AdminMenu.css";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

function AdminMenu(props) {
  const adminStatus = sessionStorage.getItem("adminStatus");
  return (
    <Box className="adminMenu">
      <Box className="adminBlock">
        <p>Designs</p>
        <Link to={"/"} onClick={(e) => props.setActiveStep(0)}>
          <Box className="adminLink">
            <img src={plus} alt="plus" />
            <p>Create New</p>
          </Box>
        </Link>
        <Link to={"/admin/mydesigns"}>
          <Box
            className={`adminLink ${props.activePage === "mydesigns" ? "adminLinkActive" : ""}`}
            onClick={(e) => props.setActivePage("mydesigns")}
          >
            <img src={my_designs} alt="my_designs" />
            <p>My Designs</p>
          </Box>
        </Link>
        <a className={`adminLink`} href="https://traceaudio.com/cart">
          <p>Shopify Cart</p>
        </a>
        {+adminStatus === 2 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Link to={"/admin/retrieve-design"}>
              <Box
                className={`adminLink ${props.activePage === "retrieve-design" ? "adminLinkActive" : ""}`}
                onClick={(e) => props.setActivePage("retrieve-design")}
              >
                <p>Retrieve design</p>
              </Box>
            </Link>
          </Box>
        )}
        <Box
          className={"adminLink"}
          onClick={(e) => {
            localStorage.removeItem("token");
            sessionStorage.removeItem("savedDesign");
            sessionStorage.removeItem("adminStatus");
            window.location.reload();
          }}
        >
          <p>Log out</p>
        </Box>
      </Box>
    </Box>
  );
}

export default AdminMenu;
