import { Link, useNavigate } from "react-router-dom";
import PlusButton from "../../../images/plus_button.svg";
import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

const RetrieveOrder = ({ setActiveStep }) => {
  const [order, setOrder] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestMade, setRequestMade] = useState(false);
  const [error, setError] = useState(false);

  const navigation = useNavigate();

  const getOrder = async () => {
    setLoading(true);
    setError(false);
    setRequestMade(false);

    await axios
      .get(`https://labelmaker.traceaudioserver.com/api/v1/orders/${search}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);

        setOrder(res.data.designs);
        navigation(`/cart-edit/${search}`);
        setRequestMade(true);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
    setLoading(false);
  };

  return (
    <Box className="myDesigns" sx={{ width: "100%" }}>
      <Box className="myDesignsHeader">
        <p>Retrieve order</p>
        <Link to="/">
          <img src={PlusButton} style={{ marginLeft: "6px" }} alt="Plus" onClick={(e) => setActiveStep(0)} />
        </Link>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "40%", maxWidth: "500px", marginTop: "10%" }}>
          {error || (!order && requestMade) ? (
            <Box>
              <Typography sx={{ marginBottom: "10px", textAlign: "center", fontFamily: "Abel", fontSize: "20px", fontWeight: 400 }}>
                {"We couldn't find any order for your query."}
              </Typography>
              <Typography sx={{ marginBottom: "10px", textAlign: "center", fontFamily: "Abel", fontSize: "20px", fontWeight: 400 }}>
                Please try again.
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default RetrieveOrder;
