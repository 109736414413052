import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Labels.css";
import logotype_placeholder from "../../images/logotype_placeholder.svg";
import Label from "./Label/Label";
import LabelMerged from "./LabelMerged/LabelMerged";
import LabelFinal from "./LabelFinal/LabelFinal";
import { Box } from "@mui/material";
import logotype from "../../images/logo-mobile.svg";
import RightEarsSamson from "../../images/right-ears-samson.svg";
import RightEarsBehringer from "../../images/right-ears-behringer.svg";
import RightEarsDbx from "../../images/right-ears-dbx.svg";
import RightEarsNeutrik from "../../images/right-ears-neutrik.svg";
import RightEarsArtP48 from "../../images/right-ears-art-p48.svg";
import RightEarsArtP16 from "../../images/right-ears-art-p16.svg";

const Labels = (props) => {
  const [template, setTemplate] = useState([]);
  const [isMouseDown, setIsMouseDown] = useState(false);

  useEffect(() => {
    setTemplate(JSON.parse(window.sessionStorage.getItem("template")));
  }, []);

  const containerRef = useRef(null);

  const scrollToElement = (index) => {
    const cell = document.getElementById(`cell-label-${index}`);
    if (cell) {
      cell.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }
  };

  useEffect(() => {
    scrollToElement(props.selectedLabel - 1);
  }, [props.selectedLabel]);

  const getRightEarsProps = useCallback(
    () =>
      ({
        samson_s_patch_plus: { src: RightEarsSamson, height: 286 },
        behringer_px3000: { src: RightEarsBehringer, height: 286 },
        black_lion_pbr_xlr: { src: RightEarsBehringer, height: 279.5 },
        black_lion_pbr_trs_trs3: { src: RightEarsBehringer, height: 276.1 },
        black_lion_pbr_trs_bt: { src: RightEarsBehringer, height: 276.1 },
        dbx_pb_48: {
          src: RightEarsDbx,
          height: 286,
          style: {
            marginTop: "auto",
            marginBottom: "auto",
          },
        },
        neutrik_nys_spp_l1: {
          src: RightEarsNeutrik,
          height: 210,
          style: { marginTop: "auto", marginBottom: "auto" },
        },
        art_p48: { src: RightEarsArtP48, height: 286 },
        art_p16_xlr: { src: RightEarsArtP16, height: 275 },
      }[template.id]),
    [template?.id]
  );

  const rightEarsProps = getRightEarsProps();

  const getLogoImage = () => {
    if (props.data[0].logotype) {
      return (
        <img
          className={`labelsLogotype ${props.data[0].logotype !== "" && "logoSelected"}`}
          src={props.data[0].logotype === "" || props.data[0].logotype === null ? logotype_placeholder : props.data[0].logotype}
          style={{ width: "102px", height: "102px" }}
          alt="Logotype"
          onClick={(e) => props.data[0].logotype === null && props.setLogoUploadOpen(true)}
        />
      );
    }
    if (props.step === "logo") {
      return (
        <>
          <Box className="labelsPlus">+</Box>
          <img
            className={`labelsLogotype ${props.data[0].logotype !== "" && "logoSelected"}`}
            src={props.data[0].logotype === "" || props.data[0].logotype === null ? logotype_placeholder : props.data[0].logotype}
            style={{ width: "102px", height: "102px" }}
            alt="Logotype"
            onClick={(e) => props.data[0].logotype === null && props.setLogoUploadOpen(true)}
          />
        </>
      );
    }

    return <img src={logotype} alt="Logo" style={{ width: "90px", height: "90px" }} />;
  };

  const getLogoBlockWr = () => {
    const logoBlock = getLogoImage();

    if (template.logoCircle === true) {
      return (
        <Box className="labelsCircleBlock">
          <Box className={"labelsLogoWrapper"} onClick={(e) => (props.step === "logo" ? props.setLogoUploadOpen(true) : null)}>
            {logoBlock}
          </Box>
        </Box>
      );
    } else {
      return (
        <Box className={"labelsLogoWrapper"} onClick={(e) => (props.step === "logo" ? props.setLogoUploadOpen(true) : null)}>
          {logoBlock}
        </Box>
      );
    }
  };

  const getLabelItem = (label, index) => {
    const commonProps = {
      index,
      key: index,
      data: label,
      globalData: props.data,
      fontClass: label?.font || props.data[0].settings[0].fontClass,
      middleLine: template.middleLine,
    };

    const labelComponent = {
      color: (
        <LabelMerged
          {...commonProps}
          setData={props.setData}
          setSelectedLabel={props.setSelectedLabel}
          selectedLabelsArray={props.selectedLabelsArray}
          setSelectedLabelsArray={props.setSelectedLabelsArray}
          selectedLabel={props.selectedLabel}
          setSelectedGroup={props.setSelectedGroup}
          pointerEvents="all"
          isMouseDown={isMouseDown}
          setIsMouseDown={setIsMouseDown}
        />
      ),
      logo: <LabelFinal {...commonProps} />,
      preview: <LabelFinal {...commonProps} />,
      label: (
        <Label
          {...commonProps}
          scrollToElement={scrollToElement}
          setData={props.setData}
          setSelectedLabel={props.setSelectedLabel}
          selectedLabel={props.selectedLabel}
          updateTopLabel={props.updateTopLabel}
          updateBottomLabel={props.updateBottomLabel}
          updateMiddleLabel={props.updateMiddleLabel}
        />
      ),
    };

    return labelComponent[props.step];
  };

  return (
    <Box>
      {template ? (
        <Box className={`labelsList ${template.id === "art_p16_xlr" ? "bigLabels" : ""}`}>
          <Box className="labelsContent labelsContentWr">
            <Box className="labelsContent labelContentContainer">
              <Box
                className={`labelsLeft ${(template.id === "neutrik_nys_spp_l1" || template.id === "art_p48") && "labelsLeftNeutrik"} ${
                  template.logoCircle && "labelsLeftCircle"
                }`}
              >
                {template.id !== "neutrik_nys_spp_l1" && <Box className="labelsTopItem" />}
                {template.id === "neutrik_nys_spp_l1" && (
                  <Box
                    style={{
                      backgroundColor: "#c1bfbf",
                      width: "59px",
                      height: "59px",
                      borderRadius: "60px",
                      position: "absolute",
                      left: "88px",
                    }}
                  />
                )}
                {getLogoBlockWr()}
                {template.id !== "neutrik_nys_spp_l1" && <Box className="labelsBottomItem" />}
              </Box>
              <Box
                ref={containerRef}
                onMouseUp={() => setIsMouseDown(false)}
                className={`labelsItemsList ${props.step === "logo" ? "labelsDark" : ""} ${
                  props.step === "preview" ? "labelsPreview" : ""
                }`}
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${template.labelsPerRow}, 1fr)`,
                }}
              >
                {props.data[0].data.map((label, index) => getLabelItem(label, index))}
              </Box>
              <img {...rightEarsProps} alt="right-ears-icon" />
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default Labels;
