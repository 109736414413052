import React, { useEffect, useState } from 'react'
import './LabelsStep.css'
import down from '../../../images/down.svg'
import Labels from '../../../Components/Labels/Labels'
import FontSelect from '../../../Popups/FontSelect/FontSelect'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import bookmark from '../../../images/bookmark.svg'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import loading from '../../../images/loading.svg'

function LabelsStep (props) {
  const [selectedLabelId, setSelectedLabel] = useState(1)
  const [fontPopupOpen, setFontPopupOpen] = useState(false)
  const [, setTopLabel] = useState('')
  const [, setBottomLabel] = useState('')
  const [, setMiddleLabel] = useState('')
  const [, setIsLabelInGroup] = useState(false)
  const [saveButton, setSaveButton] = useState('Save to My Designs')
  const [saveDesignLoading, setSaveDesignLoading] = useState(false)

  const navigation = useNavigate()

  const template = JSON.parse(window.sessionStorage.getItem('template'))
  const isSmallScreen = useMediaQuery('(max-width:530px)')

  const sendData = (apiURL) => {
    const orderData = {
      DesignName: template.name,
      DesignId: template.id,
      DesignLogotype: props.data[0].logotype || null,
      DesignData: [
        {
          data: props.data[0].data.map((item) => {
            return item
          }),
          groups: props.data[0].groups,
          settings: props.data[0].settings
        }
      ],
      DesignDescription: template.DesignDescription,
      Amount: props.data[0].amount,
      Status: 1,
      Id: props.data[0].Id !== undefined ? props.data[0].Id : null
    }

    const data = new FormData()
    props.data[0].logotype?.indexOf('http') === -1 && data.append('file', props.data[0].logotype)
    data.append('data', JSON.stringify(orderData))

    const token = localStorage.getItem('token')

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com' + apiURL,
      data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    setSaveDesignLoading(true)
    axios
      .request(config)
      .then((response) => {
        if (response.data === 'Design updated successfully') {
          setSaveButton('Your design is saved!')
          setSaveDesignLoading(false)
        } else if (response.data.designId) {
          const newData = [{ ...props.data[0], isEditing: true, Id: response.data.designId }]
          props.setData(newData)
          setSaveButton('Your design is saved!')
          setSaveDesignLoading(false)
        }
      })
      .catch((error) => {
        setSaveDesignLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    window.sessionStorage.removeItem('savedActiveStep')
  }, [])

  useEffect(() => {
    const data = props.data
    if (selectedLabelId !== null && props.data[0].data.length !== 0) {
      setTopLabel(data[0].data.find((e) => e.id === selectedLabelId).topLabel)
      setBottomLabel(data[0].data.find((e) => e.id === selectedLabelId).bottomLabel)

      if (template?.id === 'behringer_px3000') {
        setMiddleLabel(data[0].data.find((e) => e.id === selectedLabelId).middleLabel)
      }
    }
    checkIdInLabels(props.data[0].groups, selectedLabelId)
  }, [selectedLabelId])

  function checkIdInLabels (data, id) {
    for (let i = 0; i < data?.length; i++) {
      const labels = data[i]?.labels
      if (labels.includes(id)) {
        return setIsLabelInGroup(true)
      }
    }
    return setIsLabelInGroup(false)
  }

  function updateTopLabel (text) {
    if (text.length > 10) {
      return false
    } else {
      setTopLabel(text)

      const data = props.data
      const index = props.data[0].data.findIndex((e) => e.id === selectedLabelId)
      data[0].data[index].topLabel = text
      window.sessionStorage.setItem('data', JSON.stringify(data))
      props.setData(data)
    }
  }

  function updateBottomLabel (text) {
    if (text.length > 10) {
      return false
    } else {
      setBottomLabel(text)

      const data = props.data
      const index = props.data[0].data.findIndex((e) => e.id === selectedLabelId)

      data[0].data[index].bottomLabel = text
      window.sessionStorage.setItem('data', JSON.stringify(data))
      props.setData(data)
    }
  }

  function updateMiddleLabel (text) {
    if (text.length > 10) {
      return false
    } else {
      setMiddleLabel(text)

      const data = props.data
      const index = props.data[0].data.findIndex((e) => e.id === selectedLabelId)

      data[0].data[index].middleLabel = text
      window.sessionStorage.setItem('data', JSON.stringify(data))
      props.setData(data)
    }
  }

  return (
    <Box className="labelsStep">
      <Box className="stepTexts">
        <h1 className="main-title">Add Text and Select Font</h1>
        <p>{'Make your patchbay easier to read by adding text and numbers. Or don’t. This is your world, we’re just living in it.'}</p>
      </Box>
      <Box className="contentBlock">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginTop: '40px',
            flexWrap: 'wrap'
          }}
        >
          <Box sx={{ marginBottom: isSmallScreen ? '15px' : '0px' }}>
            <Button
              variant="contained"
              sx={{ borderRadius: '10px', height: '45px', backgroundColor: '#7A92BA' }}
              onClick={(e) => setFontPopupOpen(true)}
            >
              <Typography sx={{ fontFamily: 'Abel', marginRight: '10px' }}>{`${!isSmallScreen ? 'Select' : ''} Font`}</Typography>
              <img src={down} alt="Down" />
            </Button>
          </Box>
          <Box sx={{ marginBottom: isSmallScreen ? '15px' : '0px' }}>
            {window.localStorage.getItem('token') !== null
              ? (
              <Button
                variant="contained"
                sx={{ borderRadius: '10px', height: '45px', backgroundColor: '#7A92BA' }}
                disabled={saveDesignLoading}
                onClick={(e) => {
                  sendData(
                    props.data[0].isEditing === true
                      ? props.data[0].isSharedDesign === true
                        ? '/api/v1/addDesign'
                        : '/api/v1/editDesign'
                      : '/api/v1/addDesign'
                  )
                }}
              >
                {saveDesignLoading === true ? <img style={{ marginRight: '6px', width: '20px' }} src={loading} alt="Loading" /> : null}
                {!isSmallScreen && (
                  <Typography sx={{ fontFamily: 'Abel', marginRight: '10px' }}>
                    {props.data[0].isEditing === true ? (props.data[0].isSharedDesign === true ? saveButton : 'Update Data') : saveButton}
                  </Typography>
                )}
                <img src={bookmark} alt="Down" />
              </Button>
                )
              : (
              <Button
                variant="contained"
                sx={{ borderRadius: '10px', height: '45px', backgroundColor: '#7A92BA' }}
                disabled={saveDesignLoading}
                onClick={(e) => {
                  sessionStorage.setItem(
                    'savedDesign',
                    JSON.stringify({
                      ...props.data[0],
                      DesignId: template.id,
                      DesignName: template.name
                    })
                  )
                  sessionStorage.setItem('savedActiveStep', props.activeStep)
                  sessionStorage.setItem('savedTempate', template.id)
                  navigation('/login?redirect=save-design/' + template.id)
                }}
              >
                {!isSmallScreen && <Typography sx={{ fontFamily: 'Abel', marginRight: '10px' }}>Save to My Designs</Typography>}
                <img src={bookmark} alt="bookmark" />
              </Button>
                )}
          </Box>
        </Box>
        <Labels
          step={'label'}
          data={props.data}
          setData={props.setData}
          setSelectedLabel={setSelectedLabel}
          selectedLabel={selectedLabelId}
          activeStep={props.activeStep}
          updateTopLabel={updateTopLabel}
          updateBottomLabel={updateBottomLabel}
          updateMiddleLabel={updateMiddleLabel}
        />
      </Box>
      {fontPopupOpen
        ? (
        <FontSelect setFontPopupOpen={setFontPopupOpen} data={props.data} setData={props.setData} selectedLabel={selectedLabelId} />
          )
        : null}
    </Box>
  )
}

export default LabelsStep
