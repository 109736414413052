import { Link, useNavigate } from "react-router-dom";
import PlusButton from "../../../images/plus_button.svg";
import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import DesignBlock from "../MyDesigns/DesignBlock";

const RetrieveDesign = ({ setActiveStep }) => {
  const DESIGN_TAB = "DESIGN_TAB";
  const ORDER_TAB = "ORDER_TAB";
  const [designs, setDesigns] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestMade, setRequestMade] = useState(false);
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState(DESIGN_TAB);
  const [order, setOrder] = useState(null);

  const navigation = useNavigate();

  const getOrder = async () => {
    setLoading(true);
    setError(false);
    setRequestMade(false);

    await axios
      .get(`https://labelmaker.traceaudioserver.com/api/v1/orders/${search}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);

        setOrder(res.data.designs);
        navigation(`/cart-edit/${search}`);
        setRequestMade(true);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
    setLoading(false);
  };

  const getDesigns = async () => {
    setLoading(true);
    setError(false);
    setRequestMade(false);
    const endOfEndpoint = search.includes("@") ? "designs" : "design";

    await axios
      .get(`https://labelmaker.traceaudioserver.com/api/v1/${search}/${endOfEndpoint}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDesigns(res.data.designs);
        setRequestMade(true);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
    setLoading(false);
  };

  const getTabContent = () => {
    switch (activeTab) {
      case DESIGN_TAB:
        return (
          <>
            {designs.length ? (
              <>
                {designs.map((design, index) => (
                  <DesignBlock key={index} data={design} setActiveStep={setActiveStep} disableDelete={true} />
                ))}
              </>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
                <Box sx={{ display: "flex", flexDirection: "column", width: "40%", maxWidth: "500px" }}>
                  {error || (!designs.length && requestMade) ? (
                    <Box sx={{ marginTop: "20px" }}>
                      <Typography sx={{ marginBottom: "10px", textAlign: "center", fontFamily: "Abel", fontSize: "20px", fontWeight: 400 }}>
                        {"We couldn't find any designs for your query."}
                      </Typography>
                      <Typography sx={{ marginBottom: "10px", textAlign: "center", fontFamily: "Abel", fontSize: "20px", fontWeight: 400 }}>
                        Please try again.
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            )}
          </>
        );
      case ORDER_TAB:
        return (
          <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "40%", maxWidth: "500px" }}>
              {error || (!order && requestMade) ? (
                <Box sx={{ marginTop: "20px" }}>
                  <Typography sx={{ marginBottom: "10px", textAlign: "center", fontFamily: "Abel", fontSize: "20px", fontWeight: 400 }}>
                    {"We couldn't find any order for your query."}
                  </Typography>
                  <Typography sx={{ marginBottom: "10px", textAlign: "center", fontFamily: "Abel", fontSize: "20px", fontWeight: 400 }}>
                    Please try again.
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const handleButtonClick = () =>
    ({
      [DESIGN_TAB]: getDesigns,
      [ORDER_TAB]: getOrder,
    }[activeTab]);

  const getButtonTitle = () =>
    ({
      [DESIGN_TAB]: "Get Designs",
      [ORDER_TAB]: "Get Order",
    }[activeTab]);

  const getTextInputLabel = () =>
    ({
      [DESIGN_TAB]: "Email or Design ID",
      [ORDER_TAB]: "Order ID",
    }[activeTab]);

  return (
    <Box className="myDesigns" sx={{ width: "100%" }}>
      <Box className="myDesignsHeader">
        <p>Retrieve designs</p>
        <Link to="/">
          <img src={PlusButton} style={{ marginLeft: "6px" }} alt="Plus" onClick={(e) => setActiveStep(0)} />
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flex: 1,
          marginBottom: "20px",
          marginTop: "5%",
        }}
      >
        <TextField
          variant="outlined"
          label={getTextInputLabel()}
          value={search}
          InputLabelProps={{
            style: {
              fontFamily: "Abel",
            },
          }}
          InputProps={{
            slotProps: {
              input: {
                sx: {
                  fontFamily: "Abel",
                },
              },
            },
          }}
          sx={{ fontFamily: "Abel", width: "300px" }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{ display: "flex", borderWidth: "1px", borderStyle: "solid", borderRadius: "20px", borderColor: "rgba(229, 229, 229, 1)" }}
        >
          <Box
            onClick={() => setActiveTab(ORDER_TAB)}
            sx={{
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px",
              borderWidth: "1px",
              padding: "5px 10px",
              cursor: "pointer",
              width: "100px",
              textAlign: "center",
              transition: "background-color 0.2s",
              backgroundColor: activeTab === ORDER_TAB ? "rgba(110, 163, 213, 1)" : "transparent",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontFamily: "Abel" }}>Ordered Design ID</Typography>
          </Box>
          <Box
            onClick={() => setActiveTab(DESIGN_TAB)}
            sx={{
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
              borderWidth: "1px",
              padding: "5px 10px",
              cursor: "pointer",
              width: "100px",
              textAlign: "center",
              transition: "background-color 0.2s",
              backgroundColor: activeTab === DESIGN_TAB ? "rgba(110, 163, 213, 1)" : "transparent",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontFamily: "Abel" }}>Draft Design ID</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingButton
          loading={loading}
          variant="contained"
          sx={{ marginTop: "20px", fontFamily: "Abel", width: "200px" }}
          disabled={!search.length}
          onClick={() => handleButtonClick()()}
        >
          {getButtonTitle()}
        </LoadingButton>
      </Box>
      {getTabContent()}
    </Box>
  );
};

export default RetrieveDesign;
