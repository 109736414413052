import React from "react";
import left from "../../images/left.svg";
import right from "../../images/right.svg";
import { Box } from "@mui/material";
import "./BottomMenu.css";

function BottomMenu(props) {
  function backButton() {
    let currentIndex = props.activeStep - 1;

    if (currentIndex < 0) {
      currentIndex = 0;
    }

    props.setActiveStep(currentIndex);
    window.sessionStorage.setItem("step", JSON.stringify(currentIndex));
  }

  function nextButton() {
    if (props.isTemplateSelected === true) {
      let currentIndex = props.activeStep + 1;

      if (currentIndex > 4) {
        currentIndex = 4;
      }

      props.setActiveStep(currentIndex);
      window.sessionStorage.setItem("step", JSON.stringify(currentIndex));
    }
  }

  return (
    <Box className={`bottomMenu ${props.activeStep === 4 ? "noBottom" : ""}`}>
      <Box className="stepButtons">
        <button className="stepButton" onClick={(e) => backButton()} disabled={props.activeStep === 0}>
          <img src={left} alt="Left" />
          <p>Last step</p>
        </button>
        <button className="stepButton" onClick={(e) => nextButton()} disabled={props.activeStep === 4 || !props.isTemplateSelected}>
          <img src={right} alt="Left" />
          <p>Next step</p>
        </button>
      </Box>
      <Box className="priceBlock">
        <p>Total</p>
        <p>$ {props.totalAmount}</p>
      </Box>
    </Box>
  );
}

export default BottomMenu;
