import React, { useEffect, useState } from "react";
import "../Label/Label.css";
import "./LabelMerged.css";
import select_icon from "../../../images/select_icon.svg";
import double from "../../../images/double.svg";
import LabelNumbering from "../../LabelNumbering/LabelNumbering";
import fontColorContrast from "font-color-contrast";
import ColorSelect from "../../../Popups/ColorSelect/ColorSelect";
import { Modal } from "@mui/material";

const LabelMerged = ({
  index,
  middleLine,
  globalData,
  data,
  selectedLabelsArray,
  setSelectedLabelsArray,
  setSelectedGroup,
  selectedGroup,
  pointerEvents,
  fontClass,
  isMouseDown,
  setIsMouseDown,
  setData,
  colorPopupOpen,
  setColorPopupOpen,
}) => {
  const template = JSON.parse(window.sessionStorage.getItem("template"));
  const [isLabelInGroup, setIsLabelInGroup] = useState(false);
  const [fontColor, setFontColor] = useState("#000000");
  const [groupData, setGroupData] = useState([
    {
      groupRowCount: 0,
      groupRowItems: 0,
      secondRowStart: null,
    },
  ]);
  const [firstID, setFirstID] = useState(null);
  const [isBigLabel, setIsBigLabel] = useState(false);
  const [isFakeLabel, setIsFakeLabel] = useState(false);

  const isSelectedLabel = selectedLabelsArray.indexOf(data.id) !== -1;
  const centerOfDataList = Math.ceil(globalData[0].data.length / 2 + 1);

  const isSecondRowItem = data.id > globalData[0].data.length / 2;
  const isSamsonTemplate = "samson_s_patch_plus";
  const isBehringerTemplate = "behringer_px3000";
  const isDBXTemplate = "dbx_pb_48";
  const isNeutrikTemplate = "neutrik_nys_spp_l1";
  const isArtTemplate = "art_p48";
  const isArtXlrTemplate = "art_p16_xlr";
  const isBlackLionPbrXlrTemplate = "black_lion_pbr_xlr";
  const isBlackLionPbrTrsTrs3 = "black_lion_pbr_trs_trs3";
  const isBlackLionPbrTrsBt = "black_lion_pbr_trs_bt";

  const getCircleSize = () =>
    ({
      [isSamsonTemplate]: "samsonCircle",
      [isBehringerTemplate]: "behringerCircle",
      [isDBXTemplate]: "dbxCircle",
      [isNeutrikTemplate]: "neutrikCircle",
      [isArtTemplate]: "artCircle",
    }[template.id]);

  const getBigLabelPosition = () => {
    if (template.rows === 1) {
      return template.firstRowBigLabelPosition;
    } else {
      if (isSecondRowItem) {
        return template.secondRowBigLabelPosition;
      } else {
        return template.firstRowBigLabelPosition;
      }
    }
  };

  const getBottomBigLabelStyle = () =>
    ({
      [isSamsonTemplate]: "samsonBottomBigLabel",
      [isBehringerTemplate]: "behringerBottomBigLabel",
      [isDBXTemplate]: "dbxBottomBigLabel",
      [isNeutrikTemplate]: "neutrikBottomBigLabel",
      [isArtTemplate]: "artBottomBigLabel",
      [isArtXlrTemplate]: "artXlrBottomBigLabel",
      [isBlackLionPbrTrsTrs3]: "blackLionPbrTrsTrs3BottomBigLabel",
      [isBlackLionPbrTrsBt]: "blackLionPbrTrsBtBottomBigLabel",
    }[template.id]);

  const getTopBigLabelStyle = () =>
    ({
      [isSamsonTemplate]: "samsonTopBigLabel",
      [isBehringerTemplate]: "behringerTopBigLabel",
      [isDBXTemplate]: "dbxTopBigLabel",
      [isNeutrikTemplate]: "neutrikTopBigLabel",
      [isArtTemplate]: "artTopBigLabel",
      [isArtXlrTemplate]: "artXlrTopBigLabel",
    }[template.id]);

  const getTopLabelFirstRowStyle = () =>
    ({
      [isSamsonTemplate]: "samsonTopLabelFirstRow",
      [isBehringerTemplate]: "behringerTopLabelFirstRow",
      [isDBXTemplate]: "dbxTopLabelFirstRow",
      [isNeutrikTemplate]: "neutrikTopLabelFirstRow",
      [isArtTemplate]: "artTopLabelFirstRow",
      [isArtXlrTemplate]: "artXlrTopLabelFirstRow",
      [isBlackLionPbrXlrTemplate]: "blackLionPbrXlrTopLabelFirstRow",
      [isBlackLionPbrTrsTrs3]: "blackLionPbrTrsTrs3TopLabelFirstRow",
      [isBlackLionPbrTrsBt]: "blackLionPbrTrsBtTopLabelFirstRow",
    }[template.id]);

  const getBottomLabelFirstRowStyle = () =>
    ({
      [isSamsonTemplate]: "samsonBottomLabelFirstRow",
      [isBehringerTemplate]: "behringerBottomLabelFirstRow",
      [isDBXTemplate]: "dbxBottomLabelFirstRow",
      [isNeutrikTemplate]: "neutrikBottomLabelFirstRow",
      [isArtTemplate]: "artBottomLabelFirstRow",
      [isArtXlrTemplate]: "artXlrBottomLabelFirstRow",
      [isBlackLionPbrXlrTemplate]: "blackLionPbrXlrBottomLabelFirstRow",
      [isBlackLionPbrTrsTrs3]: "blackLionPbrTrsTrs3BottomLabelFirstRow",
      [isBlackLionPbrTrsBt]: "blackLionPbrTrsBtBottomLabelFirstRow",
    }[template.id]);

  const getTopLabelSecondRowStyle = () =>
    ({
      [isSamsonTemplate]: "samsonTopLabelSecondRow",
      [isBehringerTemplate]: "behringerTopLabelSecondRow",
      [isDBXTemplate]: "dbxTopLabelSecondRow",
      [isNeutrikTemplate]: "neutrikTopLabelSecondRow",
      [isArtTemplate]: "artTopLabelSecondRow",
      [isBlackLionPbrXlrTemplate]: "blackLionPbrXlrTopLabelFirstRow",
      [isBlackLionPbrTrsTrs3]: "blackLionPbrTrsTrs3TopLabelSecondRow",
      [isBlackLionPbrTrsBt]: "blackLionPbrTrsBtTopLabelSecondRow",
    }[template.id]);

  const getBottomLabelSecondRowStyle = () =>
    ({
      [isSamsonTemplate]: "samsonBottomLabelSecondRow",
      [isBehringerTemplate]: "behringerBottomLabelSecondRow",
      [isDBXTemplate]: "dbxBottomLabelSecondRow",
      [isNeutrikTemplate]: "neutrikBottomLabelSecondRow",
      [isArtTemplate]: "artBottomLabelSecondRow",
      [isBlackLionPbrXlrTemplate]: "blackLionPbrXlrBottomLabelFirstRow",
      [isBlackLionPbrTrsTrs3]: "blackLionPbrTrsTrs3BottomLabelSecondRow",
      [isBlackLionPbrTrsBt]: "blackLionPbrTrsBtBottomLabelSecondRow",
    }[template.id]);

  const getBigLabelWidth = () => {
    if (template.id === isBlackLionPbrXlrTemplate) {
      if (groupData.containFakeLabels) {
        return groupData.groupRowItems * 167 - 108 + "px";
      }
      if (groupData.containedLeftFakeLabels) {
        return groupData.groupRowItems * 167 + 58 + "px";
      }
      if (groupData.containedRightFakeLabels) {
        return groupData.groupRowItems * 167 - 167 + "px";
      }
      return groupData.groupRowItems * 167 + "px";
    }
    if (template.id === isBlackLionPbrTrsBt || template.id === isBlackLionPbrTrsTrs3) {
      if (groupData.containedLeftFakeLabels) {
        return groupData.groupRowItems * 118 + 58 + "px";
      }
      if (groupData.containedRightFakeLabels) {
        return groupData.groupRowItems * 118 - 118 + "px";
      }
      if (groupData.containFakeLabels) {
        return groupData.groupRowItems * 118 - 30 + "px";
      }
      return groupData.groupRowItems * 118 + "px";
    }
    if (groupData.containFakeLabels) {
      return groupData.groupRowItems * 110 - 80 + "px";
    }
    if (isBigLabel) {
      return groupData.groupRowItems * 110 + "px";
    }
    if (!isBigLabel) {
      return groupData.groupRowItems * 110 + "px";
    }
  };

  const getLabelNumber = (id) => {
    if (template?.id === isBehringerTemplate) {
      if (data.id > 1 && data.id < template.labelsPerRow) {
        return id - 1;
      }
      if (data.id > template?.labelsPerRow + 1) {
        return id - 3;
      }
    }
    return id;
  };

  const labelNumber = getLabelNumber(data.id);
  const bigLabelWidth = getBigLabelWidth();

  const checkIdInLabels = (data, cell) => {
    for (let i = 0; i < data.length; i++) {
      const labels = data[i].labels;
      if (labels.includes(cell.id)) {
        const color = fontColorContrast(data[i].color || "#ffffff");
        setFontColor(color);
        return setIsLabelInGroup(true);
      }
    }
    const color = fontColorContrast(cell.color || "#ffffff");
    setFontColor(color);
    return setIsLabelInGroup(false);
  };

  useEffect(() => {
    checkIdInLabels(globalData[0].groups, data);
  }, [globalData]);
  useEffect(() => {}, [selectedLabelsArray]);

  const isCellSelectable = (cellNumber) => {
    const rowLength = template.labelsPerRow;

    const isCellSelected = (num) => selectedLabelsArray.includes(num);

    const neighborsOneRow = [cellNumber - 1, cellNumber + 1];

    const neighborsTwoRows = [cellNumber - rowLength, cellNumber + rowLength, cellNumber - 1, cellNumber + 1];

    const neighbors = template.rows === 1 ? neighborsOneRow : neighborsTwoRows;

    if (template.id === "behringer_px3000") {
      if (cellNumber === 13) neighbors.push(15);
      if (cellNumber === 15) neighbors.push(13);
      if (cellNumber === 40) neighbors.push(42);
      if (cellNumber === 42) neighbors.push(40);
      if (
        (cellNumber === 14 && isCellSelected(13) && isCellSelected(15)) ||
        (cellNumber === 41 && isCellSelected(40) && isCellSelected(42))
      ) {
        return true;
      }
    }

    return neighbors.some((neighbor) => isCellSelected(neighbor));
  };

  const handleCellClick = (currentID) => {
    const groupList = [...globalData[0].groups];

    const selectedGroup = groupList.find((e) => e.labels?.indexOf(currentID) !== -1);

    if (selectedLabelsArray.indexOf(currentID) === -1) {
      /* GET TEMPLATE DATA */

      if (isCellSelectable(currentID) || selectedLabelsArray.length === 0) {
        setSelectedLabelsArray((prev) => [...prev, currentID]);
      }
      // if (!isCellSelectable(currentID) && selectedLabelsArray.length !== 0) {
      //     return setSelectedLabelsArray([currentID]);
      // }
      const futureSelectedLabelsArray = [...selectedLabelsArray, currentID];
      if (
        futureSelectedLabelsArray.some((num) => num <= template.labelsPerRow) &&
        futureSelectedLabelsArray.some((num) => num > template.labelsPerRow)
      ) {
        const labelsList = [...selectedLabelsArray];
        for (const label of selectedLabelsArray) {
          if (label <= template.labelsPerRow) {
            labelsList.push(label + template.labelsPerRow);
          }
          if (label > template.labelsPerRow) {
            labelsList.push(label - template.labelsPerRow);
          }
        }
        setSelectedLabelsArray([...new Set(labelsList)]);
      }
    } else {
      const newData = [];

      selectedLabelsArray.forEach((item, index) => {
        if (item < currentID) {
          newData.push(item);
        }
      });

      setSelectedLabelsArray(newData);
    }

    if (selectedGroup !== undefined && selectedLabelsArray.length > 1) {
      setSelectedLabelsArray((prev) => [...prev, ...selectedGroup?.labels]);
    } else if (selectedGroup !== undefined) {
      setSelectedLabelsArray(selectedGroup?.labels);
    }

    if (selectedGroup?.labels?.every((num) => selectedLabelsArray.includes(num) === true)) {
      const newData = [];

      selectedLabelsArray.forEach((item, index) => {
        if (selectedGroup?.labels?.indexOf(item) === -1) {
          newData.push(item);
        }
      });

      setSelectedLabelsArray(newData);
    }
  };

  const [labelStyle, setLabelStyle] = useState({
    backgroundColor: "",
    borderTopLeftRadius: "",
    borderTopRightRadius: "",
    borderBottomLeftRadius: "",
    borderBottomRightRadius: "",
    borderColor: "",
  });

  useEffect(() => {
    const currentID = data.id;

    const groupData = [...globalData[0].groups].find((e) => e.labels.indexOf(currentID) !== -1);
    const rowLength = template.labelsPerRow;

    const topNeighbor = globalData[0].data.find((cell) => cell.id === currentID - rowLength);
    const rightNeighbor = globalData[0].data.find((cell) => cell.id === currentID + 1);
    const bottomNeighbor = globalData[0].data.find((cell) => cell.id === currentID + rowLength);
    const leftNeighbor = globalData[0].data.find((cell) => cell.id === currentID - 1);

    const hasColoredTopNeighbor = !!topNeighbor?.color && topNeighbor?.color !== "#ffffff";
    const hasColoredRightNeighbor = !!rightNeighbor?.color && rightNeighbor?.color !== "#ffffff";
    const hasColoredBottomNeighbor = !!bottomNeighbor?.color && bottomNeighbor?.color !== "#ffffff";
    const hasColoredLeftNeighbor = !!leftNeighbor?.color && leftNeighbor?.color !== "#ffffff";

    const styles = {
      backgroundColor: "",
      borderTopLeftRadius: "",
      borderTopRightRadius: "",
      borderBottomLeftRadius: "",
      borderBottomRightRadius: "",
      borderColor: "",
    };

    if (template.rows === 2) {
      styles.borderTopLeftRadius = "0px";
      styles.borderTopRightRadius = "0px";
      styles.borderBottomLeftRadius = "0px";
      styles.borderBottomRightRadius = "0px";

      if (data.id < template.labelsPerRow + 1) {
        styles.borderTopLeftRadius = "8px";
        styles.borderTopRightRadius = "8px";

        // 1 row
      } else {
        styles.borderBottomLeftRadius = "8px";
        styles.borderBottomRightRadius = "8px";
        // 2 row
      }
    } else {
      styles.borderBottomLeftRadius = "8px";
      styles.borderBottomRightRadius = "8px";
      styles.borderTopLeftRadius = "8px";
      styles.borderTopRightRadius = "8px";
    }

    if (globalData[0].data.length > 0) {
      globalData[0].data.forEach((label, index) => {
        if (label.id === data.id) {
          styles.backgroundColor = label.color + " !important";
        } else {
          styles.backgroundColor = "#F0EEF0";
        }
      });

      styles.backgroundColor = globalData[0].data[data.id - 1].color;
    }

    if (globalData[0].groups.length > 0) {
      globalData[0].groups.forEach((group, index) => {
        if (group?.labels.indexOf(data.id) !== -1) {
          styles.backgroundColor = group.color;
        }
      });
    }

    if (!groupData) {
      hasColoredTopNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID - rowLength))
        ? (styles.borderTopColor = "#000000")
        : (styles.borderTopStyle = "hidden");
      hasColoredRightNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID + 1))
        ? (styles.borderRightColor = "#000000")
        : (styles.borderRightStyle = "hidden");
      template.rows === 2 && currentID === template.labelsPerRow
        ? (styles.borderRightColor = "#FFFFFF")
        : (styles.borderRightColor = "#000000");
      hasColoredBottomNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID + rowLength))
        ? (styles.borderBottomColor = "#000000")
        : (styles.borderBottomStyle = "hidden");
      hasColoredLeftNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID - 1))
        ? (styles.borderLeftColor = "#000000")
        : (styles.borderLeftStyle = "hidden");
    }

    if (groupData !== undefined) {
      function updateStyles(
        topLeft,
        topRight,
        bottomLeft,
        bottomRight,
        topColor = "#000000",
        rightColor = "#000000",
        bottomColor = "#000000",
        leftColor = "#000000",
        topStyle = "hidden",
        rightStyle = "hidden",
        bottomStyle = "hidden",
        leftStyle = "hidden"
      ) {
        styles.borderTopLeftRadius = `${topLeft}px`;
        styles.borderTopRightRadius = `${topRight}px`;
        styles.borderBottomLeftRadius = `${bottomLeft}px`;
        styles.borderBottomRightRadius = `${bottomRight}px`;
        styles.borderTopColor = `${topColor}`;
        styles.borderRightColor = `${rightColor}`;
        styles.borderBottomColor = `${bottomColor}`;
        styles.borderLeftColor = `${leftColor}`;
        styles.borderTopStyle = `${topStyle}`;
        styles.borderRightStyle = `${rightStyle}`;
        styles.borderBottomStyle = `${bottomStyle}`;
        styles.borderLeftStyle = `${leftStyle}`;
      }

      const hasTopNeighbor = groupData.labels.includes(currentID - rowLength);
      const hasBottomNeighbor = groupData.labels.includes(currentID + rowLength);
      const hasLeftNeighbor = groupData.labels.includes(currentID - 1);
      const hasRightNeighbor = groupData.labels.includes(currentID + 1);
      if (template.rows === 2) {
        if (!hasTopNeighbor && !hasLeftNeighbor) {
          updateStyles(8, 0, 0, 0, "#000000", groupData.color, groupData.color, "#000000", "solid", "hidden", "hidden", "solid");
        }
        if (!hasTopNeighbor && !hasRightNeighbor) {
          updateStyles(0, 8, 0, 0, "#000000", "#000000", groupData.color, groupData.color, "solid", "solid", "hidden", "hidden");
        }
        if (!hasBottomNeighbor && !hasLeftNeighbor) {
          updateStyles(0, 0, 8, 0, groupData.color, groupData.color, "#000000", "#000000", "hidden", "hidden", "solid", "solid");
        }
        if (!hasBottomNeighbor && !hasRightNeighbor) {
          updateStyles(0, 0, 0, 8, groupData.color, "#000000", "#000000", groupData.color, "hidden", "solid", "solid", "hidden");
        }
        if (hasLeftNeighbor && hasRightNeighbor) {
          updateStyles(0, 0, 0, 0, "#000000", groupData.color, "#000000", groupData.color, "solid", "hidden", "solid", "hidden");
        }
        if (!hasTopNeighbor && hasLeftNeighbor && !hasRightNeighbor) {
          updateStyles(0, 8, 0, 0, "#000000", "#000000", groupData.color, groupData.color, "solid", "solid", "hidden", "hidden");
        }
        if (hasTopNeighbor && !hasLeftNeighbor && !hasRightNeighbor) {
          updateStyles(0, 0, 8, 8, groupData.color, "#000000", "#000000", "#000000", "hidden", "solid", "solid", "solid");
        }
        if (hasBottomNeighbor && !hasLeftNeighbor && !hasRightNeighbor) {
          updateStyles(8, 8, 0, 0, "#000000", "#000000", groupData.color, "#000000", "solid", "solid", "hidden", "solid");
        }
        if (!hasTopNeighbor && hasLeftNeighbor && hasRightNeighbor && hasBottomNeighbor) {
          updateStyles(0, 0, 0, 0, "#000000", groupData.color, groupData.color, groupData.color, "solid", "hidden", "hidden", "hidden");
        }
        if (hasTopNeighbor && hasLeftNeighbor && hasRightNeighbor && !hasBottomNeighbor) {
          updateStyles(0, 0, 0, 0, groupData.color, groupData.color, "#000000", groupData.color, "hidden", "hidden", "solid", "hidden");
        }
        if (!hasLeftNeighbor && !hasTopNeighbor && !hasBottomNeighbor && hasRightNeighbor) {
          updateStyles(8, 0, 0, 0, "#000000", groupData.color, "#000000", "#000000", "solid", "hidden", "solid", "solid");
        }
        if (!hasRightNeighbor && !hasTopNeighbor && !hasBottomNeighbor && hasLeftNeighbor) {
          updateStyles(0, 8, 0, 0, "#000000", "#000000", "#000000", groupData.color, "solid", "solid", "solid", "hidden");
        }
        if (!hasLeftNeighbor && !hasTopNeighbor && !hasBottomNeighbor && hasRightNeighbor && currentID > rowLength) {
          updateStyles(0, 0, 8, 0, "#000000", groupData.color, "#000000", "#000000", "solid", "hidden", "solid", "solid");
        }
        if (hasLeftNeighbor && !hasTopNeighbor && !hasBottomNeighbor && !hasRightNeighbor && currentID > rowLength) {
          updateStyles(0, 0, 0, 8, "#000000", "#000000", "#000000", groupData.color, "solid", "solid", "solid", "hidden");
        }
      } else {
        if (!hasRightNeighbor && hasLeftNeighbor) {
          updateStyles(0, 8, 0, 8, "#000000", "#000000", "#000000", groupData.color, "solid", "solid", "solid", "hidden");
        }
        if (hasRightNeighbor && hasLeftNeighbor) {
          updateStyles(0, 0, 0, 0, "#000000", groupData.color, "#000000", groupData.color, "solid", "hidden", "solid", "hidden");
        }
        if (hasRightNeighbor && !hasLeftNeighbor) {
          updateStyles(8, 0, 8, 0, "#000000", groupData.color, "#000000", "#000000", "solid", "hidden", "solid", "solid");
        }
      }
    }
    setLabelStyle(styles);
  }, [globalData]);

  function updateSelectedGroup() {
    if (globalData[0].groups.length !== 0) {
      const foundGroup = globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1);
      if (foundGroup) {
        setSelectedGroup(foundGroup.id);
      }
    }
  }

  useEffect(() => {
    if (globalData[0]?.groups?.length > 0) {
      const currentGroup = globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1);

      const labels = currentGroup?.labels;

      if (labels?.length > 0) {
        const newData = {
          groupRowCount: 0,
          groupRowItems: 0,
          secondRowStart: null,
          containFakeLabels: false,
        };

        if (template.id === "behringer_px3000") {
          if ((labels.includes(13) && labels.includes(15)) || (labels.includes(40) && labels.includes(42))) {
            newData.containFakeLabels = true;
          }
        }
        if (template.id === isBlackLionPbrXlrTemplate) {
          if (labels.includes(1) && labels.includes(18)) {
            newData.containFakeLabels = true;
          }
          if (labels.includes(1)) {
            newData.containedLeftFakeLabels = true;
          }
          if (labels.includes(18)) {
            newData.containedRightFakeLabels = true;
          }
        }
        if (template.id === isBlackLionPbrTrsTrs3 || template.id === isBlackLionPbrTrsBt) {
          if (labels.includes(1) || labels.includes(27)) {
            newData.containedLeftFakeLabels = true;
          }
          if (labels.includes(26) || labels.includes(52)) {
            newData.containedRightFakeLabels = true;
          }
          if (labels.includes(1) || labels.includes(27)) {
            newData.containFakeLabels = true;
          }
          if (labels.includes(26) || labels.includes(52)) {
            newData.containFakeLabels = true;
          }
        }

        if (labels.every((num) => num <= template.labelsPerRow)) {
          newData.groupRowCount = 1;
        }
        if (labels.every((num) => num > template.labelsPerRow)) {
          newData.groupRowCount = 1;
        }
        if (labels.some((num) => num <= template.labelsPerRow) && labels.some((num) => num > template.labelsPerRow)) {
          newData.groupRowCount = 2;
        }

        newData.groupRowItems = labels.length / newData.groupRowCount;
        newData.secondRowStart = labels.some((num) => num > template.labelsPerRow) ? labels[newData.groupRowItems] : null;

        if (currentGroup !== undefined) {
          setFirstID(labels[0]);
        }
        setGroupData(newData);
      }
    }
  }, [globalData]);

  useEffect(() => {
    if (template.firstLarge === true) {
      const cornerLabels = [1, template.labelsPerRow, template.labelsPerRow + 1, template.labelsPerRow * template.rows];

      if (cornerLabels.includes(data.id)) {
        setIsBigLabel(true);
      }
    }

    let fakeLabelsArray = [];

    if (template.fakeLabels === true) {
      const fakeLabelID = (template.labelsPerRow / 2).toFixed(0);

      if (template.rows > 1) {
        fakeLabelsArray = [Number(fakeLabelID), Number(fakeLabelID) + Number(template.labelsPerRow)];
      }
    }

    if (fakeLabelsArray.includes(data.id)) {
      setIsFakeLabel(true);
    }
  }, []);

  const handleMouseDown = (cellNumber) => {
    setIsMouseDown(true);
    handleCellClick(cellNumber);
  };

  const handleMouseOver = (cellNumber) => {
    if (isMouseDown) {
      handleCellClick(cellNumber);
    }
  };

  return (
    <>
      {template.double === true ? (
        <div
          onMouseDownCapture={() => {
            handleMouseDown(data.id);
          }}
          onMouseDown={() => {
            updateSelectedGroup();
          }}
          onMouseOver={() => handleMouseOver(data.id)}
          style={labelStyle}
          className={`labelItem doubleLabel EditHide
          ${!isLabelInGroup && data.color !== "white" && data.color !== "#ffffff" && "labelItemBorder"}
          ${isFakeLabel === true ? "fakeLabel" : ""}
          ${pointerEvents === "none" ? "pointerNone" : ""}
          ${isSelectedLabel ? "mergeActiveLabel" : ""}`}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            className={`${selectedLabelsArray.indexOf(data.id) !== -1 ? "mergeActiveLabel" : ""}`}
          />
          {(getBigLabelPosition() === 1 && !!isLabelInGroup && firstID === data.id) ||
          (isLabelInGroup === true && groupData.secondRowStart === data.id) ? (
            <p
              className={`labelHorCenter ${isSelectedLabel && "mergeActiveLabelP"} ${fontClass}`}
              style={
                ({ color: fontColor },
                isBigLabel === true
                  ? { width: groupData.groupRowItems * 175 - 135 + 215 + "px" }
                  : { width: groupData.groupRowItems * 175 + "px" })
              }
            >
              {globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)?.topText}
            </p>
              ) : null}
          <div
            className={`${isSecondRowItem && template.id !== isArtXlrTemplate ? getTopLabelSecondRowStyle() : getTopLabelFirstRowStyle()}`}
          >
            <p
              style={{
                color: fontColor,
                marginLeft: data.id === 1 ? "30px" : "auto",
                marginRight: data.id === template.labelsPerRow ? "30px" : "auto",
              }}
              className={`topLabel ${isSelectedLabel && "mergeActiveLabelP"} ${fontClass} ${data.topLabel ? "changedLabel" : "Edit"}`}
            >
              {!!isLabelInGroup && getBigLabelPosition() === 1 ? "" : data.topLabel}
            </p>
          </div>
          <img
            src={double}
            style={index === 0 ? { left: "45px" } : {}}
            className={`doubleLabelImage unselectable ${data.id % 2 ? "" : "transparent"}`}
            alt="Double"
          />
          {(getBigLabelPosition() === 2 && !!isLabelInGroup && firstID === data.id) ||
          firstID === data.id ||
          (!!isLabelInGroup && groupData.secondRowStart === data.id) ? (
            <p
              className={`labelHorCenterBottom unselectable ${
                isSelectedLabel && "mergeActiveLabelP"
              } ${getBottomBigLabelStyle()} ${fontClass}`}
              style={{
                color: fontColor,
                width: isBigLabel ? groupData.groupRowItems * 175 - 135 + 215 + "px" : groupData.groupRowItems * 175 + "px",
              }}
            >
              {globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)?.bottomText}
            </p>
              ) : null}
          <div
            className={`${
              isSecondRowItem && template.id !== isArtXlrTemplate ? getBottomLabelSecondRowStyle() : getBottomLabelFirstRowStyle()
            }`}
          >
            <p
              style={{
                color: fontColor,
                marginLeft: data.id === 1 ? "30px" : "auto",
                marginRight: data.id === template.labelsPerRow ? "30px" : "auto",
              }}
              className={`bottomLabel ${isSelectedLabel && "mergeActiveLabelP"} ${fontClass} ${data.bottomLabel ? "changedLabel" : "Edit"}`}
            >
              {!!isLabelInGroup && getBigLabelPosition() === 2 ? "" : data.bottomLabel}
            </p>
          </div>
          <LabelNumbering id={data.id} globalData={globalData} />
        </div>
      ) : (
        <>
          <div
            onMouseDownCapture={() => {
              handleMouseDown(data.id);
            }}
            onMouseDown={() => {
              updateSelectedGroup();
            }}
            onMouseOver={() => handleMouseOver(data.id)}
            style={{ ...labelStyle }}
            className={`labelItem EditHide mergeLabel ${template.id === isBlackLionPbrTrsBt && "blackLionPbrTrsBtCellWr"} ${
              template.id === isBlackLionPbrTrsTrs3 && "blackLionPbrTrsTrs3CellWr"
            } ${template.id === isBlackLionPbrXlrTemplate && "blackLionPbrXlrCellWr"} ${
              ((data.id === globalData[0].data.length && template.id === isBlackLionPbrTrsTrs3) ||
                (data.id === 1 && template.id === isBlackLionPbrTrsTrs3) ||
                (data.id === centerOfDataList && template.id === isBlackLionPbrTrsTrs3) ||
                (data.id === centerOfDataList - 1 && template.id === isBlackLionPbrTrsTrs3)) &&
              "blackLionPbrTrsTrs3SmallCellWr"
            } ${
              ((data.id === globalData[0].data.length && template.id === isBlackLionPbrTrsBt) ||
                (data.id === 1 && template.id === isBlackLionPbrTrsBt) ||
                (data.id === centerOfDataList && template.id === isBlackLionPbrTrsBt) ||
                (data.id === centerOfDataList - 1 && template.id === isBlackLionPbrTrsBt)) &&
              "blackLionPbrTrsTrs3SmallCellWr"
            } ${
              ((data.id === globalData[0].data.length && template.id === isBlackLionPbrXlrTemplate) ||
                (data.id === 1 && template.id === isBlackLionPbrXlrTemplate)) &&
              "blackLionPbrXlrSmallCellWr"
            } ${!isLabelInGroup && data.color !== "white" && data.color !== "#ffffff" && "labelItemBorder"}  ${
              template.id === isArtTemplate && (data.id === 1 || data.id === centerOfDataList) && "artWiderItemLeft"
            } ${
              template.id === isArtTemplate &&
              (data.id === centerOfDataList - 1 || data.id === globalData[0].data.length) &&
              "artWiderItemRight"
            } ${isFakeLabel === true ? "fakeLabel" : ""}
            ${isBigLabel && "onlyOneLabel"}
          ${pointerEvents === "none" ? "pointerNone" : ""} `}
          >
            <div className={`${!!middleLine && data.id < template.labelsPerRow + 1 ? "middleLine" : ""} pointerNone`} />
            <div
              className={`${
                template.id === isBlackLionPbrTrsBt && data.id === centerOfDataList - 2 && "blackLionPbrTrsBtRectangle"
              } pointerNone`}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              className={`${selectedLabelsArray.indexOf(data.id) !== -1 ? "mergeActiveLabel" : ""}`}
            />
            <div
              className={` ${
                template.id === isBehringerTemplate && (data.id === 1 || data.id === centerOfDataList) && "largeTemplateLabelWr"
              }`}
            />
            {(getBigLabelPosition() === 1 && !!isLabelInGroup && firstID === data.id) ||
            (getBigLabelPosition() === 1 && !!isLabelInGroup && groupData.secondRowStart === data.id) ? (
              <p
                className={`labelHorCenter unselectable ${isSelectedLabel && "mergeActiveLabelP"} ${getTopBigLabelStyle()} ${fontClass} `}
                style={{ width: bigLabelWidth, color: fontColor }}
              >
                {globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)?.topText}
              </p>
                ) : null}
            <div
              className={`${isBigLabel && "hideLabel"} ${
                template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && "hideLabel"
              }
               ${isSecondRowItem ? getTopLabelSecondRowStyle() : getTopLabelFirstRowStyle()}`}
            >
              <p
                style={{ color: fontColor }}
                className={`topLabel unselectable ${isSelectedLabel && "mergeActiveLabelP"} ${
                  template.id === isBehringerTemplate &&
                  (data.id === centerOfDataList - 1 || data.id === globalData[0].data.length) &&
                  "mirroredLargeItemLabel"
                } ${fontClass} ${data.topLabel ? "changedLabel" : "Edit"} `}
              >
                {isLabelInGroup && getBigLabelPosition() === 1 ? "" : data.topLabel}
              </p>
            </div>
            <div
              className={`labelCircle unselectable ${
                template.id === isBlackLionPbrTrsBt && data.id === centerOfDataList - 2 && "blackLionPbrTrsBtRectangleFirstRow"
              } ${
                template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && "blackLionPbrTrsBtRectangleSecondRow"
              } ${template.id === isBlackLionPbrXlrTemplate && "blackLionPbrXlrCircle"} ${isSelectedLabel && "mergeActiveLabelCircle"} ${
                template.id === isBehringerTemplate &&
                (data.id === centerOfDataList - 1 || data.id === globalData[0].data.length) &&
                "mirroredLargeItemCircle"
              } ${getCircleSize()}
            ${
              template.id === isBehringerTemplate &&
              (data.id === centerOfDataList || data.id === globalData[0].data.length) &&
              "behringerCircleBottom"
            }
             `}
            >
              <img
                className={`mergeSelectMark ${template.id === isBlackLionPbrXlrTemplate && "mergeSelectMarkBalckLionPbrXlr"} ${
                  isSelectedLabel && "mergeActiveLabelMergeSelectMark"
                }`}
                src={select_icon}
                alt="Select"
              />
            </div>
            {(getBigLabelPosition() === 2 && !!isLabelInGroup && firstID === data.id) ||
            (getBigLabelPosition() === 2 && !!isLabelInGroup && groupData.secondRowStart === data.id) ? (
              <p
                className={`labelHorCenterBottom ${isSelectedLabel && "mergeActiveLabelP"} ${getBottomBigLabelStyle()} ${fontClass} `}
                style={{ width: bigLabelWidth, color: fontColor }}
              >
                {globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)?.bottomText}
              </p>
                ) : null}
            <div
              className={`bottomLabelWr ${
                template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && "blackLionPbrTrsBtBottomLabelWrMargin"
              } ${template.id === isBlackLionPbrTrsBt && data.id === centerOfDataList - 2 && "hideLabel"} ${
                template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && "blackLionPbrTrsBtPreLastInput"
              } ${isBigLabel && "hideLabel"}
              ${isSecondRowItem ? getBottomLabelSecondRowStyle() : getBottomLabelFirstRowStyle()}`}
            >
              <p
                style={{ color: fontColor }}
                className={`bottomLabel unselectable ${isSelectedLabel && "mergeActiveLabelP"} ${
                  template.id === isBehringerTemplate &&
                  (data.id === centerOfDataList - 1 || data.id === globalData[0].data.length) &&
                  "mirroredLargeItemLabel"
                } ${fontClass} ${data.bottomLabel ? "changedLabel" : "Edit"} `}
              >
                {!!isLabelInGroup && getBigLabelPosition() === 2 ? "" : data.bottomLabel}
              </p>
            </div>
            {isBigLabel && (
              <p
                id="oneLabelInput"
                style={{ color: fontColor }}
                className={`onlyOneLabelInput ${template.id === isBlackLionPbrXlrTemplate && "blackLionPbrXlrOnlyOneLabel"} ${fontClass} ${
                  data.middleLabel ? "changedLabel" : "Edit"
                }`}
              >
                {data.middleLabel}
              </p>
            )}
            <div
              className={`${
                template.id === isBehringerTemplate && (data.id === 1 || data.id === centerOfDataList) && "largeTemplateLabelWr"
              }`}
            />
            {(template.id === isBehringerTemplate ||
              template.id === isBlackLionPbrTrsTrs3 ||
              template.id === isBlackLionPbrTrsBt ||
              template.id === isBlackLionPbrXlrTemplate) &&
            (data.id === 1 ||
              data.id === template.labelsPerRow ||
              data.id === template.labelsPerRow + 1 ||
              data.id === template.labelsPerRow * template.rows) ? null : (
              <LabelNumbering id={labelNumber} globalData={globalData} />
              )}
          </div>
        </>
      )}
      {colorPopupOpen ? (
        <Modal open={colorPopupOpen} onClose={() => setColorPopupOpen(false)}>
          <ColorSelect
            clearSelectedLabels={setSelectedLabelsArray}
            setColorPopupOpen={setColorPopupOpen}
            selectedLabelsArray={selectedLabelsArray}
            selectedGroup={selectedGroup}
            data={data}
            setData={setData}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default LabelMerged;
