import React, { useEffect, useRef, useState } from 'react'
import './ColorStep.css'
import Labels from '../../../Components/Labels/Labels'
import down from '../../../images/down.svg'
import merge from '../../../images/merge.svg'
import split from '../../../images/split.svg'
import ColorSelect from '../../../Popups/ColorSelect/ColorSelect'
import { Modal, Box, Button, Typography, useMediaQuery } from '@mui/material'
import bookmark from '../../../images/bookmark.svg'
import loading from '../../../images/loading.svg'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import fontColorContrast from 'font-color-contrast'

const ColorStep = (props) => {
  const templateFromLocalStogare = JSON.parse(sessionStorage.getItem('template'))

  const [colorPopupOpen, setColorPopupOpen] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState(null)
  const [selectedLabelsArray, setSelectedLabelsArray] = useState([])
  const [mergeDisabled, setMergeDisabled] = useState(true)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true)
  const [template] = useState(templateFromLocalStogare)
  const [saveButton, setSaveButton] = useState('Save to My Designs')
  const [saveDesignLoading, setSaveDesignLoading] = useState(false)
  const [, setTopLabel] = useState('')
  const [, setBottomLabel] = useState('')

  const isSmallScreen = useMediaQuery('(max-width: 530px)')

  const navigation = useNavigate()
  const topInputRef = useRef(null)
  const bottomInputRef = useRef(null)

  const sendData = (apiURL) => {
    const orderData = {
      DesignName: template.name,
      DesignId: template.id,
      DesignLogotype: props.data[0].logotype || null,
      DesignData: [
        {
          data: props.data[0].data.map((item) => {
            return item
          }),
          groups: props.data[0].groups,
          settings: props.data[0].settings
        }
      ],
      DesignDescription: template.DesignDescription,
      Amount: props.data[0].amount,
      Status: 1,
      Id: props.data[0].Id !== undefined ? props.data[0].Id : null
    }

    const data = new FormData()
    if (props.data[0].logotype?.indexOf('http') === -1) {
      data.append('file', props.data[0].logotype)
    }
    data.append('data', JSON.stringify(orderData))

    const token = localStorage.getItem('token')

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com' + apiURL,
      data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    setSaveDesignLoading(true)
    axios
      .request(config)
      .then((response) => {
        if (response.data === 'Design updated successfully') {
          setSaveButton('Your design is saved!')
          setSaveDesignLoading(false)
        } else if (response.data.designId) {
          const newData = [{ ...props.data[0], isEditing: true, Id: response.data.designId }]
          props.setData(newData)
          setSaveButton('Your design is saved!')
          setSaveDesignLoading(false)
        }
      })
      .catch((error) => {
        setSaveDesignLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    if (template?.id === 'black_lion_pbr_trs_bt' && (selectedLabelsArray.includes(25) || selectedLabelsArray.includes(51))) {
      setMergeDisabled(true)
    } else if (selectedLabelsArray.length > 1) {
      setMergeDisabled(false)
    } else {
      setMergeDisabled(true)
    }
  }, [selectedLabelsArray])

  const getGroupsContainingSelectedLabels = (selectedLabelsArray, groups) => {
    const result = new Set()

    for (const label of selectedLabelsArray) {
      for (const group of groups) {
        if (group.labels.includes(label)) {
          result.add(group.id)
        }
      }
    }

    return [...result]
  }

  const removeGroupsByIds = (groups, groupIdsToRemove) => {
    return groups.filter((group) => !groupIdsToRemove.includes(group.id))
  }

  const setLabelFontColor = () => {
    const copiedData = JSON.parse(JSON.stringify(props.data))
    for (let i = 0; i < copiedData[0].data.length; i++) {
      const groupLabelIndex = copiedData[0].groups.findIndex((group) => group.labels.includes(copiedData[0].data[i].id))
      const groupLabel = groupLabelIndex !== -1 ? copiedData[0].groups[groupLabelIndex] : null
      if (groupLabel) {
        const colorOfGroupLabel = fontColorContrast(groupLabel.color)
        copiedData[0].groups[groupLabelIndex].fontColor = colorOfGroupLabel
      }
      const colorOfSingleLabel = fontColorContrast(copiedData[0].data[i].color)
      copiedData[0].data[i].fontColor = colorOfSingleLabel
    }
    props.setData(copiedData)
  }

  function mergeLabels () {
    const newData = JSON.parse(JSON.stringify(props.data))

    const newLabels = [...new Set(selectedLabelsArray)]

    const labels = newLabels.sort((a, b) => a - b)

    if (template.id === 'behringer_px3000') {
      if (labels.indexOf(13) !== -1 && labels.indexOf(15) !== -1) {
        labels.push(14)
      }

      if (labels.indexOf(40) !== -1 && labels.indexOf(42) !== -1) {
        labels.push(41)
      }
    }

    const newLabelGroup = {
      id: (Date.now() * Math.random()).toFixed(0),
      labels,
      color: '#FFFFFF',
      topText: '',
      bottomText: ''
    }

    const resetedLabels = newData[0].data.map((label) => {
      const isSecondRowItem = label.id > newData[0].data.length / 2

      if (labels.includes(label.id)) {
        const position = isSecondRowItem ? template.secondRowBigLabelPosition : template.firstRowBigLabelPosition

        return ({
          ...label,
          topLabel: position === 1 ? '' : label.topLabel,
          bottomLabel: position === 2 ? '' : label.bottomLabel
        })
      }
      return label
    })

    newData[0].data = resetedLabels

    const containingGroups = getGroupsContainingSelectedLabels(labels, props.data[0].groups)

    if (containingGroups.length) {
      const groupsAfterRemoving = removeGroupsByIds(newData[0].groups, containingGroups)
      newData[0].groups = groupsAfterRemoving
      newData[0].groups.push(newLabelGroup)
    } else {
      newData[0].groups.push(newLabelGroup)
    }

    setSelectedGroup(newLabelGroup.id)
    props.setData(newData)
    setColorPopupOpen(true)
  }

  useEffect(() => {
    window.sessionStorage.removeItem('savedActiveStep')
  }, [])

  useEffect(() => {
    let ifAllLabesInGroup = false
    const groupList = [...props.data[0].groups]

    selectedLabelsArray.forEach((number) => {
      const selectedGroup = groupList.find((e) => e.labels.indexOf(number) !== -1)

      if (selectedGroup !== undefined) {
        ifAllLabesInGroup = true
      }
    })

    if (
      (selectedLabelsArray.length === 1 && ifAllLabesInGroup === false) ||
      (selectedLabelsArray.length === 0 && ifAllLabesInGroup === false)
    ) {
      setDeleteButtonDisabled(true)
    } else {
      if (ifAllLabesInGroup) {
        setDeleteButtonDisabled(false)
      } else {
        setDeleteButtonDisabled(true)
      }
    }
  }, [selectedLabelsArray])

  function deleteLabelGroup () {
    const newData = JSON.parse(JSON.stringify(props.data))
    const currentGroupColor = newData[0].groups.find((e) => e.id === selectedGroup)?.color
    console.log('currentGroup', currentGroupColor)
    const filteredGroups = newData[0].groups.filter((group) => group.id !== selectedGroup)
    newData[0].groups = filteredGroups
    newData[0].data.forEach((label, index) => {
      if (selectedLabelsArray.includes(label.id)) {
        newData[0].data[index].color = currentGroupColor
      }
    })

    selectedLabelsArray.forEach((number) => {
      console.log('number', number)
      newData[0].groups.forEach((group, index) => {
        if (group.labels.includes(number)) {
          newData[0].groups[index].labels = newData[0].groups[index].labels.filter((label) => label !== number)
        }
      })
    })

    props.setData(newData)
    setSelectedLabelsArray([])
    setSelectedGroup(null)
  }

  useEffect(() => {
    sessionStorage.removeItem('savedDesign')
  }, [])

  useEffect(() => {
    setTopLabel(props.data[0]?.groups.find((e) => e.id === selectedGroup)?.topText)
    setBottomLabel(props.data[0]?.groups.find((e) => e.id === selectedGroup)?.bottomText)
  }, [selectedGroup])

  useEffect(() => {
    setLabelFontColor()
  }, [selectedLabelsArray])

  return (
    <Box className="labelsStep">
      <Box className="stepTexts">
        <h1 className="main-title">Merge and Color Patch Points</h1>
        <p>
          Organize your label by merging patch points into groups and coloring them for easy and quick navigation… or make it all one big
          blob! The world is your oyster.
        </p>
      </Box>
      <Box className="contentBlock">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row' }}>
          <Box style={{ display: 'flex', marginTop: '40px' }}>
            {selectedLabelsArray.length > 0
              ? (
              <button className="buttonWithIcon" onClick={(e) => setColorPopupOpen(true)} style={{ marginRight: '20px' }}>
                <p>Select Color</p>
                <img src={down} alt="Down" />
              </button>
                )
              : null}
            <button className={'buttonAction'} disabled={mergeDisabled} onClick={(e) => mergeLabels()}>
              <img src={merge} alt="Merge" />
              <p>Merge</p>
            </button>

            <button className="buttonAction" disabled={deleteButtonDisabled} onClick={(e) => deleteLabelGroup()}>
              <img src={split} alt="Merge" />
              <p>Split</p>
            </button>
          </Box>
          <Box sx={{ marginTop: '40px' }}>
            {window.localStorage.getItem('token') !== null
              ? (
              <Button
                variant="contained"
                sx={{ borderRadius: '10px', height: '45px', backgroundColor: '#7A92BA' }}
                disabled={saveDesignLoading}
                onClick={(e) => {
                  sendData(
                    props.data[0].isEditing === true
                      ? props.data[0].isSharedDesign === true
                        ? '/api/v1/addDesign'
                        : '/api/v1/editDesign'
                      : '/api/v1/addDesign'
                  )
                }}
              >
                {saveDesignLoading === true ? <img style={{ marginRight: '6px', width: '20px' }} src={loading} alt="Loading" /> : null}
                {!isSmallScreen && (
                  <Typography sx={{ fontFamily: 'Abel', marginRight: '10px' }}>
                    {props.data[0].isEditing === true ? (props.data[0].isSharedDesign === true ? saveButton : 'Update Data') : saveButton}
                  </Typography>
                )}
                <img src={bookmark} alt="Down" />
              </Button>
                )
              : (
              <Button
                variant="contained"
                sx={{ borderRadius: '10px', height: '45px', backgroundColor: '#7A92BA' }}
                disabled={saveDesignLoading}
                onClick={(e) => {
                  sessionStorage.setItem(
                    'savedDesign',
                    JSON.stringify({
                      ...props.data[0],
                      DesignId: template.id,
                      DesignName: template.name
                    })
                  )
                  sessionStorage.setItem('savedActiveStep', props.activeStep)
                  sessionStorage.setItem('savedTempate', template.id)
                  navigation('/login?redirect=save-design/' + template.id)
                }}
              >
                {!isSmallScreen && <Typography sx={{ fontFamily: 'Abel', marginRight: '10px' }}>Save to My Designs</Typography>}
                <img src={bookmark} alt="bookmark" />
              </Button>
                )}
          </Box>
        </Box>
        <Box>
          <Labels
            data={props.data}
            setData={props.setData}
            setSelectedLabel={setSelectedLabel}
            selectedLabel={selectedLabel}
            step={'color'}
            setSelectedGroup={setSelectedGroup}
            selectedLabelsArray={selectedLabelsArray}
            setSelectedLabelsArray={setSelectedLabelsArray}
            activeStep={props.activeStep}
            topInputRef={topInputRef}
            bottomInputRef={bottomInputRef}
          />
        </Box>
        {colorPopupOpen
          ? (
          <Modal open={colorPopupOpen} onClose={() => setColorPopupOpen(false)}>
            <ColorSelect
              clearSelectedLabels={setSelectedLabelsArray}
              setColorPopupOpen={setColorPopupOpen}
              selectedLabelsArray={selectedLabelsArray}
              selectedGroup={selectedGroup}
              data={props.data}
              setData={props.setData}
            />
          </Modal>
            )
          : null}
      </Box>
    </Box>
  )
}

export default ColorStep
